<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text"><b>Berita Desa</b></div>
      <b-button squared variant="success" @click="btnAddOnClick">Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" />
          </template>
        </Card>
      </div>
    </div>

    <b-modal id="modal-news" size="xl" title="" hide-footer>
      <b-row no-gutters>
        <b-col md="12">
          <Form
            :form="form"
            :route="'news'"
            v-if="FormDetailActv == false && FormEditActv == false"
          />
          <FormEdit
            :form="form"
            :route="'news/' + form.id"
            v-if="FormEditActv == true"
          />
          <!-- <FormDetail :form="form" :route="'news/' + form.id" v-if="FormDetailActv == true"/> -->
          <FormDetail :data="detailData" v-if="FormDetailActv == true" />
        </b-col>
      </b-row>
    </b-modal>

    <!-- <b-card no-body class="overflow-hidden">
      <b-row no-gutters>
        <b-col md="12">
          <Form :form="form" :route="'news'" v-if="FormDetailActv == false && FormEditActv == false"/>
          <FormEdit :form="form" :route="'news/' + form.id" v-if="FormEditActv == true"/>
          <FormDetail :form="form" :route="'news/' + form.id" v-if="FormDetailActv == true"/>
        </b-col>
      </b-row>
    </b-card> -->
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/view/components/websites/NewsForm.vue";
import FormEdit from "@/view/components/websites/NewsFormEdit.vue";
import FormDetail from "@/view/components/websites/NewsFormDetail.vue";
import Table from "@/view/components/websites/NewsTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "website-news",
  data() {
    return {
      form: {
        title: "",
        excerpt: "",
        content: "",
        date: new Date().toISOString().substr(0, 10),
        author: "",
        tags: "",
        post_category_id: 1,
        image: "",
        status: "",
        yt_link: "",
      },
      FormEditActv: false,
      // detail
      FormDetailActv: false,
      detailData:"",
    };
  },
  components: {
    Card,
    Table,
    Form,
    FormEdit,
    FormDetail,
  },
  methods: {
    resetFormOnNew() {
      // reset form on new (tambah data)
      var self = this;
      Object.keys(this.form).forEach(function (key, index) {
        if (key === "image") {
          self.form[key] = [];
        } else if (key === "_method") {
          self.form[key] = "post";
        } else {
          self.form[key] = "";
        }
      });
    },

    scrollToElement(options) {
      let el = this.$el.getElementsByClassName("pagination")[0];
      el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el);
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew();
      }
    },

    scrollToTop(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("alert")[0];
      let er = this.$el.getElementsByClassName("alert");
      // el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el);
      console.log("er", er);
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew();
      }
    },

    btnAddOnClick() {
      // this.scrollToElement({ behavior: 'smooth' })
      this.FormDetailActv = false;
      this.FormEditActv = false;
      this.$bvModal.show("modal-news");
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Berita Desa", route: "" }]);

    // Scroll ToTop
    // this.$root.$on("scrollToTop", () => {
    //   // reset form on new (tambah data)
    //   this.resetFormOnNew()
    // });

    // save data success
    this.$root.$on("successSaveData", () => {     
      this.resetFormOnNew();
      this.$root.$emit("updateWebsitePost")
      this.$bvModal.hide("modal-news");
    });

    this.$root.$on("cancelAddWebsitePost", () => {
      // reset form on new (tambah data)
      // this.scrollToTop({ behavior: "smooth" });
      this.resetFormOnNew();
      this.$bvModal.hide("modal-news");
    });

    // //  Edit Website Post
    // this.$root.$on("getWebsitePostById", (data) => {
    //   this.FormDetailActv = false
    //   this.FormEditActv = true
    //  this.scrollToElement({ behavior: 'smooth' })
    //   console.log("data", data)

    //   if(data.status == 0){
    //     data.status = false
    //   }else if(data.status == 1){
    //     data.status = true
    //   }

    //  this.form = data
    //  console.log("form", this.form)
    //  this.form['_method'] = 'put'
    // });

    // // Cancel Edit
    // this.$root.$on("cancelWebsitePostUpdate", () => {
    //   this.FormEditActv = false
    //   this.scrollToTop({ behavior: 'smooth' })
    //   this.resetFormOnNew()
    // });

    // Detail Web Post
    this.$root.$on("getWebsitePostByIdDetail", (news) => {  
      this.FormDetailActv = true;
      this.FormEditActv = false;
      this.detailData = news
      this.$bvModal.show("modal-news");

    });
    
    // Detail Web Post
    this.$root.$on("editDataOnClick", (news) => {  
      this.FormDetailActv = false;
      this.FormEditActv = true;
      this.form = news
      this.$bvModal.show("modal-news");

    });
  },
};
</script>
